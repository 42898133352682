<template>
    <div class="center-div">
    <div class="close-icon" @click="closeGallery">
        <v-icon class="closeButton">mdi-close</v-icon>
    </div>
      <v-carousel v-model="initialIndex" hide-delimiters height="90vh" style="margin: 0 auto;">
        <v-carousel-item v-for="i in 24" :key="i">
          <img :src="`https://emiga-tech.pl/v3test/photo/${i}.jpg`" class="carouselPhoto" alt="brak" />
        </v-carousel-item>
      </v-carousel>
    </div>
  </template>
  
  <script>
  export default {
    name: "GalleryComponent",
    props: {
        initialIndex: {
        type: Number,
        default: 0
        }
    },
    data: () => ({
      DisplayHeight: 100,
      images: [
        {
          url:
            "https://www.tapetynapulpitkomputera.pl/uploads/5560150ad285b_1920x1080.jpg",
        },
        {
          url:
            "https://www.tapetynapulpitkomputera.pl/uploads/5560150ad285b_1920x1080.jpg",
        },
        {
          url:
            "https://images.pexels.com/photos/1334116/pexels-photo-1334116.jpeg?cs=srgb&dl=pexels-irina-iriser-1334116.jpg&fm=jpg",
        },
      ],
    }),
    methods: {
    closeGallery() {
      this.$emit("close");
    },
  },
  };
  </script>
  
  <style>
  .center-div {
    background-color: rgba(48, 48, 48, 0.9);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Make sure it's always on top */
  }

  .v-carousel-item {
    position: relative;
    }

    .carouselPhoto {
        max-height: 90vh;
        max-width: 90vw;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

    }

    .close-icon {
        position: absolute;
        top: 20px;
        right: 30px;
        cursor: pointer;
        height: 50px;
    }

    .closeButton{
        scale: 2;
        color: black !important;
    }
  </style>