<template>
    <v-footer dark padless>
    <v-card
      flat
      tile
      class="black white--text py-5 px-5 text-center"
      width="100%"
    >
    <v-row class="d-flex justify-center">
            <v-btn>
            <v-icon>mdi-home</v-icon>
            <span class="ml-4 md-4 footer-font none">eMiga-Tech</span>
            </v-btn>
            <v-btn
            href="https://goo.gl/maps/gzURgLSusRNqSuwv8"
            target="_blank"
            text
          >
            <v-icon>mdi-map-marker-radius-outline</v-icon>
            <span class="ml-4 md-4 footer-font capitalize">Modlińska 6, 03-216 Warszawa</span>
          </v-btn>


          <v-btn
            href="tel:+48600577685"
            text
          >
            <v-icon>mdi-phone-in-talk</v-icon>
            <span class="ml-4 md-4 footer-font">+48 600 577 685</span>

          </v-btn>

          <v-btn
            href="mailto:biuro@emiga-tech.pl"
            text
          >
            <v-icon>mdi-email-outline</v-icon>
            <span class="ml-4 md-4 footer-font text-lowercase">biuro@emiga-tech.pl</span>

          </v-btn>
    </v-row>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      showFooter: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const currentPosition = window.pageYOffset || document.documentElement.scrollTop;
      const maxPosition = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      if (currentPosition >= maxPosition) {
        this.showFooter = true;
      } else {
        this.showFooter = false;
      }
    }
  }
}
</script>
<style>
.v-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  transform: translateY(100%);
}
.footer-font {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
}
.capitalize {
  text-transform: capitalize
  }
.none {
  text-transform: none
  }
.show-footer {
  transform: translateY(0%);
}
.v-btn + .v-btn {
  margin-left: 10px;
}
</style>
