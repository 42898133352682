<template>
  <v-app id="offer" :style="{ background: $vuetify.theme.themes.dark.background }">
    <white-box v-show="ShowBox" @close="ShowBox = !ShowBox" :initialIndex="initialIndex" />
    <v-container>
      <nav-bar />
      <v-row>
        <v-col cols="12" md="6">
          <v-img src="https://emiga-tech.pl/photo/logo_na_tle.jpg" contain max-height="500"></v-img>
        </v-col>
        <v-col cols="12" md="6">
          <div class="content centered-content">
            <h1 class="white--text centered-title">e<span style="color:red;">M</span>iga-Tech</h1>
            <p class="grey--text">
              Działamy w branży technicznej. Obsługujemy obiekty przemysłowe zaczynając od budowy instalacji oraz montażu urządzeń po przeglądy i serwisy.
              Współpracujemy z zakładami produkcyjnymi, firmami kosmetycznymi, przemysłowymi i komunalnymi oczyszczalniami ścieków. <br />
              <br />
            </p>
          </div>
        </v-col>
      </v-row>
      <h1 class="custom-title">Nasze realizacje</h1>
      <v-row>
        <v-col v-for="i in 24" :key="i" :cols="12" sm="4">
          <v-img 
          @click="displayImage(i)"
          @mouseover="zoomInImage"
          @mouseout="zoomOutImage"
          :src="`https://emiga-tech.pl/photo/${i}.jpg`" 
          width="400" 
          height="400"
          class="zoomable-image">
        </v-img>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import WhiteBox from '@/components/WhiteBox.vue';

export default {
  name: "GalleryView",
  components: {
    NavBar,
    Footer,
    WhiteBox,
  },
  data: () => ({
    ShowBox: false,
    initialIndex: 1,
  }),
  methods: {
    displayImage(index) {
      console.log("Hello, world!");
      this.ShowBox = !this.ShowBox;
      this.initialIndex = index-1;
    },
    zoomInImage(event) {
      event.currentTarget.style.transform = 'scale(1.1)';
      event.currentTarget.style.transitionDuration = '1.5s';
      event.currentTarget.style.cursor = 'pointer';
    },

    zoomOutImage(event) {
      event.currentTarget.style.transform = 'scale(1)';
      event.currentTarget.style.transitionDuration = '1.5s';
      event.currentTarget.style.cursor = 'auto';
    },
  },
};
</script>

<style scoped>
.top {
  margin-top: 180px;
}
.bgColor1 {
  background-color: #1e1e1e;
}
.custom-title {
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 30px;
}
.content {
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.centered-content {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.zoomable-image {
  overflow: hidden;
  transition-property: transform;
  transition-duration: 0.3s;
  cursor: pointer;
}
.zoomable-image:hover {
  transform: scale(1.1);
}
.centered-title {
margin-bottom: 5px;
text-align: center;
}
</style>
<style>
.v-input__control input, .v-input__control textarea {
  color: white !important;
}
.theme--light.v-label {
    color: white !important;
}
</style>
