<template>
  <v-app id="work" :style="{ background: $vuetify.theme.themes.dark.background }">
    <v-container>
      <nav-bar />
      <v-row>
        <v-col cols="12" md="6">
          <v-img src="https://emiga-tech.pl/photo/logo_na_tle.jpg" contain max-height="500"></v-img>
        </v-col>
        <v-col cols="12" md="6">
          <div class="content centered-content">
            <h1 class="white--text centered-title">e<span style="color:red;">M</span>iga-Tech</h1>
            <p class="grey--text">
              Działamy w branży technicznej. Obsługujemy obiekty przemysłowe zaczynając od budowy instalacji oraz montażu urządzeń po przeglądy i serwisy.
              Współpracujemy z zakładami produkcyjnymi, firmami kosmetycznymi, przemysłowymi i komunalnymi oczyszczalniami ścieków. <br />
              <br />
            </p>
          </div>
        </v-col>
      </v-row>
      <h1 class="custom-title">Dołącz do naszego zespołu</h1>
      <v-row>
        <v-col cols="12" md="6" v-for="item in items" :key="item.id">
          <v-card :class="item.color + ' block'" :style="{ backgroundColor: getBackgroundColor(item.color) }">
            <v-icon x-large class="icon" :color="getIconColor(item.color)">{{ item.icon }}</v-icon>
            <h3 class="title white--text">{{ item.title }}</h3>
            <p class="description white--text" v-html="getDescription(item)"></p>
          </v-card>
        </v-col>
      </v-row>
      <h1 class="normal-size">
        Jesteś zainteresowany? Prześlij nam swoje CV na
        <a class="email-link" href="mailto:biuro@emiga-tech.pl">biuro@emiga-tech.pl</a>
      </h1>
    </v-container>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "WorkView",
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      items: [
        { id: 1, title: "Poszukujemy ", description: "Doświadczonych monterów instalacji przemysłowych PVC, PP, PE gotowych do wyjazdów służbowych na projekty zlokalizowane na terenie całego kraju.", color: "bgcolor1", icon: "mdi-account-wrench-outline"},
        { id: 2, title: "Oferujemy", description: "", color: "bgcolor2", icon: "mdi-handshake-outline"},
        { id: 3, title: "Poszukujemy ", description: "Doświadczonego spawacza TIG instalacji ze stali nierdzewnej gotowych do wyjazdów służbowych na projekty zlokalizowane na terenie całego kraju", color: "bgcolor1", icon: "mdi-account-wrench-outline"},
        { id: 4, title: "Poszukujemy ", description: "Pracowników fizycznych do mycia i czyszczenia zbiorników i obiektów na terenie Warszawy", color: "bgcolor2", icon: "mdi-account-wrench-outline"},
      ],
    };
  },
  computed: {
    listDescription() {
      return `
        <ul>
          <li>umowę o pracę / B2B / umowę zlecenie</li>
          <li>zakwaterowanie podczas wyjazdów</li>
          <li>delegacje</li>
          <li>auta służbowe do wykonywania obowiązków</li>
          <li>niezbędne narzędzia</li>
          <li>pakiet socjalny (Lux Med., MultiSport, ubezpieczenie grupowe)</li>
        </ul>
      `;
    },
  },
  methods: {
    getBackgroundColor(color) {
      if (color === "bgcolor1") {
        return "#1e1e1e";
      } else if (color === "bgcolor2") {
        return "#ce1d2a";
      } 
    },
    getIconColor(color) {
      if (color === "bgcolor1") {
        return "#A7121D";
      } else if (color === "bgcolor2") {
        return "#fff";
      } 
    },
    getDescription(item) {
      if (item.id === 2) {
        return this.listDescription;
      } else {
        return item.description;
      }
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  color: #fff;
  margin-top: 30px;
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 10px;
}

.block {
  text-align: left;
  padding: 20px;
  margin-bottom: 15px;
  width: 500px;
  height: 350px;
}

.icon {
  margin-bottom: 10px;
}

.custom-title {
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 30px;
}

.description {
  font-size: 1em;
  margin-top: 20px;
}

.content {
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centered-content {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.centered-title {
  margin-bottom: 5px;
  text-align: center;
}

.normal-size {
  color: #fff;
  font-weight: bold;
  margin-top: 25px;
  font-size: 20px;
}

.email-link {
  text-decoration: underline;
  color: #fff;
}
</style>
