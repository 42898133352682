<template>
  <v-app id="work" :style="{ background: $vuetify.theme.themes.dark.background }">
    <v-container>
      <nav-bar />
      <v-row>
        <v-col cols="12" md="6">
          <v-img src="https://emiga-tech.pl/photo/logo_na_tle.jpg" contain max-height="500"></v-img>
        </v-col>
        <v-col cols="12" md="6">
          <div class="content centered-content">
            <h1 class="white--text centered-title">e<span style="color:red;">M</span>iga-Tech</h1>
            <p class="grey--text">
              Działamy w branży technicznej. Obsługujemy obiekty przemysłowe zaczynając od budowy instalacji oraz montażu urządzeń po przeglądy i serwisy.
              Współpracujemy z zakładami produkcyjnymi, firmami kosmetycznymi, przemysłowymi i komunalnymi oczyszczalniami ścieków. <br />
              <br />
            </p>
          </div>
        </v-col>
      </v-row>
      <h1 class="custom-title">Skontaktuj się z nami</h1>
      <v-row>
        <v-col cols="12" md="6">
          <div class="bgColor1">
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="name"
                  :counter="30"
                  :rules="nameRules"
                  label="Temat"
                  required
                  style="color: white !important;"
                ></v-text-field>

                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Twój E-mail"
                  required
                ></v-text-field>

                <v-textarea  
                  v-model="message"
                  :rules="messageRules"
                  label="Twoja Wiadomość"
                  required
                ></v-textarea>

                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  @click="sendEmail"
                >
                  Wyślij
                </v-btn>

                <v-btn color="error" class="mr-4" @click="reset">
                  Wyczyść
                </v-btn>
              </v-form>
            </v-container>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="googlemap">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2440.2182773856402!2d20.999627251521595!3d52.29389256061413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ec95afc87d86b%3A0x10c14e4fae6dee6!2sModli%C5%84ska%206%2C%2003-216%20Warszawa!5e0!3m2!1spl!2spl!4v1680016275748!5m2!1spl!2spl" width="100%" height="378"></iframe>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import emailjs from 'emailjs-com';

export default {
  name: "ContactView",
  data: () => ({
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 5 && v.length <= 30) || 'Topic must be more than 5 characters and less than 30',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      message: '',
      messageRules: [
        v => !!v || 'Massage is required',
        v => (v && v.length >= 10) || 'Message must be more than 10 characters',
      ],
    }),

    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      sendEmail() {
      emailjs.init('Oa8nnGHI5vBYQ3Ikb');

      const templateParams = {
        from_name: this.name,
        from_email: this.email,
        message: this.message,
      };

      emailjs.send('service_fabj5uv', 'template_3743pxl', templateParams)
        .then(() => {
          alert('Wiadomość wysłana');
          this.reset(); 
        })
        .catch((error) => {
          alert('Błąd podczas wysyłania wiadomości:', error);
        });
    },
  },
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style scoped>
.custom-title {
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 30px;
}
.bgColor1 {
  background-color: #1e1e1e;
}
.content {
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centered-content {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.centered-title {
margin-bottom: 5px;
text-align: center;
}
</style>
<style>
.v-input__control input, .v-input__control textarea {
  color: white !important;
}
.theme--light.v-label {
    color: white !important;
}
</style>