<template>
  <v-app-bar app color="#000000" hide-on-scroll dark flat class="px-8">
    <div class="d-flex align-center">
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        src="https://emiga-tech.pl/img/logo_biale.png"
        transition="scale-transition"
        width="200"
      />
    </div>

    <v-spacer></v-spacer>

    <div class="d-sm-flex d-none">
      <router-link to="/">
        <v-btn text>
          <span :class="{'nav-link': true, 'active': currentRoute === '/'}">Strona Główna</span>
        </v-btn>
      </router-link>

      <router-link to="/Offer">
        <v-btn text>
          <span :class="{'nav-link': true, 'active': currentRoute === '/Offer'}">Oferta</span>
        </v-btn>
      </router-link>

      <router-link to="/Gallery">
        <v-btn text>
          <span :class="{'nav-link': true, 'active': currentRoute === '/Gallery'}">Galeria</span>
        </v-btn>
      </router-link>

      <router-link to="/Work">
        <v-btn text>
          <span :class="{'nav-link': true, 'active': currentRoute === '/Work'}">Praca</span>
        </v-btn>
      </router-link>

      <router-link to="/Contact">
        <v-btn text>
          <span :class="{'nav-link': true, 'active': currentRoute === '/Contact'}">Kontakt</span>
        </v-btn>
      </router-link>

    </div>

    <div class="d-flex d-sm-none">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-list>
          <router-link to="/">
            <v-list-item :class="{'active': currentRoute === '/'}">
              <v-list-item-title>Strona Główna</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/Offer">
            <v-list-item :class="{'active': currentRoute === '/Offer'}">
              <v-list-item-title>Oferta</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/Gallery">
            <v-list-item :class="{'active': currentRoute === '/Gallery'}">
              <v-list-item-title>Galeria</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/Work">
            <v-list-item :class="{'active': currentRoute === '/Work'}">
              <v-list-item-title>Praca</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/Contact">
            <v-list-item :class="{'active': currentRoute === '/Contact'}">
              <v-list-item-title>Kontakt</v-list-item-title>
            </v-list-item>
          </router-link>

        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  computed: {
    currentRoute() {
      return this.$route.path;
    }
  }
}
</script>

<style>
.nav-link {
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
}

a {
  text-decoration: none;
}

.active {
  color: red;
}
</style>
